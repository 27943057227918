import React from 'react'
import './style.scss'
import { StaticImage } from 'gatsby-plugin-image'

import Intro from '../../animations/Intro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import Newsletter from '../Form/CF7/Newsletter'
import SocialLinks from '../SocialLinks'
import { useInView } from 'react-intersection-observer'
import { Link } from 'gatsby'

function Footer(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })

	// Get footer Image from WP Site Settings
	const data = null
	// useStaticQuery(graphql`
	// 	query {
  //   	...acfOptions
  // 	}
	// `)
	//const image = data?.wp?.options?.acfOptions?.footerImage?.localFile?.childImageSharp.gatsbyImageData
	//const copy = data?.wp?.options?.acfOptions?.copy

	const d = new Date()
	const year = d.getFullYear()

  return (
		<footer ref={io} className='master-footer c5'>
			<div className='flex-12 text-left padd padd-top-off padd-bottom-off'>
				<div className='span-4'>
					<Intro visible={ioInView} in={{ fadeBottom: 750 }} delayIn={0} mounted={true} stay={true} className="logo">
						<h6>Adres</h6>
						<div className='contact '>
							ul. Mińska 25 <br/>
							03-808 Warszawa <br/>
	
						</div>
					</Intro>
				</div>
				<div className='span-4'>
					<Intro visible={ioInView} in={{ fadeBottom: 750 }} delayIn={100} mounted={true} stay={true} className="logo">
						<h6>Kontakt</h6>
						<a href="mailto:contact@bezpiecznywschod.org">contact@bezpiecznywschod.org</a><br/>
						
					</Intro>
				</div>
				<div className='span-4'>
					<Intro visible={ioInView} in={{ fadeBottom: 750 }} delayIn={200} mounted={true} stay={true} className="logo">
						<h6>KRS</h6>
						0000557191 <br/>
						<h6>STATUT</h6>
						<Link to="/statut">LINK</Link>
					</Intro>
				</div>
			</div>
		</footer>
  )
}

export default Footer