import React from 'react'
import './style.scss'

function SocialLinks(props) {

	const data = {
		icons: [
			{
				icon: 'facebook',
				link: '#'
			},
			{
				icon: 'instagram',
				link: '#'
			},
			{
				icon: 'youtube',
				link: '#'
			},
		]
	}


	const icons = data?.icons?.map((node,i) => {

		let icon = node.icon === 'facebook' ? 'FaceBook' :
						   node.icon === 'twitter' ? 'Twitter' :
						   node.icon === 'youtube' ? 'YouTube' :
						   node.icon === 'instagram' ? 'Instagram' :
						   node.icon === 'git' ? 'Git' :
							 null
		return i === 0 ? (
			<div key={i} className={`social-link`}>
				<a href={node.link} target='_blank' rel='noopener noreferrer'>
					{icon}
				</a>
			</div>
		) : (
			<div key={i} className={`social-link`}> | 
				<a href={node.link} target='_blank' rel='noopener noreferrer'>
					{icon}
				</a>
			</div>
		)
	})

	
  return (
		<div className={`social-links ${props.classes}`}>
			{icons}
		</div>
  )
}

export default SocialLinks